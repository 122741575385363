import React from "react";
import { Location } from "@reach/router";
import l from "lodash";

const withPathPairs = ComponentToWrap => props => {
	return (
		<Location>
			{({ location, navigate }) => {
				const path = l.trim(location.pathname, "/");
				const pathParts = path.split("/");
				const urlVars = {};
				for (var idx = pathParts.length - 2; idx > -1; idx = idx - 2) {
					urlVars[pathParts[idx]] = pathParts[idx + 1];
				}
				return <ComponentToWrap {...props} location={location} navigate={navigate} urlVars={urlVars} />;
			}}
		</Location>
	);
};

export const createPathPairs = pairs => {
	var path = "";
	const keys = Object.keys(pairs);
	l.forEach(keys, key => {
		if (pairs[key] !== undefined) path += `${key}/${pairs[key]}/`;
	});
	path = l.trim(path, "/");
	return path;
};

export const changePathPairs = (pairs, newPairs) => {
	// const existingKeys = Object.keys(pairs);
	// const newKeys = Object.keys(newPairs);
	// const result = [];
	// existingKeys.forEach(existingKey => {
	// 	if (newPairs[existingKey]) {
	// 		result[existingKey] = newPairs[existingKey];
	// 	}
	// })
	// newKeys.forEach(newKey => {
	// 	if (newPairs[newKey] && newPairs[newKey] !== "" && newPairs[newKey] !== undefined) {
	// 		result[newKey] = newPairs[newKey];
	// 	}
	// })
	// _.mer
	return createPathPairs(l.merge({ ...pairs }, newPairs));
	// const clonedPairs = { ...pairs };
	// l.forEach(Object.keys(newPairs), key => {
	// 	clonedPairs[key] = newPairs[key];
	// });
	// console.log("changePathPairs", pairs, clonedPairs);
	// return createPathPairs(clonedPairs);
};

export default withPathPairs;
